import { forwardRef } from 'react';
import { SvgIcon } from '@mui/material';

const HelpIcon = ({ forwardedRef, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 20 20" ref={forwardedRef} {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z" />
      <path d="M10.545 11.393H9.05c.004-.352.031-.655.082-.909.055-.257.146-.49.275-.697.133-.207.309-.412.528-.615.183-.164.343-.32.48-.469.137-.148.244-.3.322-.457a1.2 1.2 0 0 0 .117-.533c0-.227-.035-.414-.105-.563a.714.714 0 0 0-.305-.345.982.982 0 0 0-.504-.117c-.168 0-.324.037-.468.11a.87.87 0 0 0-.358.329c-.09.148-.136.344-.14.586h-1.7c.012-.535.135-.977.37-1.324.238-.352.556-.612.955-.78a3.355 3.355 0 0 1 1.341-.257c.547 0 1.016.09 1.407.27.39.175.69.435.896.778.207.34.31.754.31 1.243 0 .34-.066.642-.199.908a3.106 3.106 0 0 1-.521.732c-.215.227-.451.461-.709.703-.223.2-.375.409-.457.627-.078.219-.12.479-.123.78zm-1.67 1.81c0-.25.086-.457.258-.621.172-.168.402-.252.691-.252.285 0 .514.084.686.252a.814.814 0 0 1 .263.621.819.819 0 0 1-.263.615c-.172.168-.4.252-.686.252-.289 0-.52-.084-.691-.252a.828.828 0 0 1-.258-.615z" />
    </SvgIcon>
  );
};

export default forwardRef((props, ref) => <HelpIcon {...props} forwardedRef={ref} />);
