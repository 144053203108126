import { Outlet, useOutletContext } from 'react-router-dom';
import { Stack, Container } from '@mui/material';
import Header from 'components/Layouts/Components/Header';
import Footer from 'components/Layouts/Components/Footer';

const PublicLayout = ({ dark = false, maxWidth = 'sm', whiteLabel = false }) => {
  const context = useOutletContext();

  return (
    <Stack
      className="AppPublicLayout"
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        minHeight: '100%',
        backgroundColor: dark ? 'blue.dark' : 'white'
      }}
    >
      {!whiteLabel && <Header dark={dark} publicPage />}
      <Container maxWidth={maxWidth} sx={{ my: 5, px: { md: 8 } }}>
        <Outlet context={context} />
      </Container>
      {!whiteLabel && <Footer dark={dark} />}
    </Stack>
  );
};

export default PublicLayout;
