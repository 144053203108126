import { SvgIcon } from '@mui/material';

const RoleIcon = (props) => {
  return (
    <SvgIcon viewBox="-0.5 0 26 26" {...props}>
      <path d="M13.981 22.775c-.006-.056-.006-.112-.019-.168a3.23 3.23 0 0 0-.068-.338 3.275 3.275 0 0 0-.225-.644 3.089 3.089 0 0 0-.357-.58 4.034 4.034 0 0 0-1.018-.907 5.705 5.705 0 0 0-1.238-.575 8.754 8.754 0 0 0-1.325-.338c-.9-.162-1.812-.218-2.725-.225-.912 0-1.825.063-2.725.225-.45.082-.894.194-1.325.338-.431.15-.85.331-1.237.575a3.809 3.809 0 0 0-1.02.906 3.086 3.086 0 0 0-.355.581 3.272 3.272 0 0 0-.225.644c-.032.113-.044.225-.07.338-.012.056-.012.112-.018.168a1.532 1.532 0 0 0-.012.17c-.013.224-.007.824-.007 1.055h2v-.943c0-.032.007-.057.007-.088.006-.025 0-.056.006-.081.012-.05.012-.106.031-.15.044-.194.119-.331.225-.481.063-.07.125-.144.206-.22.088-.068.175-.143.294-.212a2.97 2.97 0 0 1 .375-.2c.144-.062.288-.125.45-.175a7.84 7.84 0 0 1 1.038-.262A13.676 13.676 0 0 1 7.012 21c.82 0 1.625.057 2.37.188.368.069.718.156 1.037.262.162.05.306.113.45.175.137.063.268.132.375.2.112.07.206.144.293.213.082.069.144.144.207.219.106.143.18.287.225.48.012.045.018.1.03.15.007.026.007.057.007.082 0 .031.006.056.006.088V24h2c-.012-.23-.012-.825-.018-1.056 0-.056-.013-.112-.013-.169zM11 13c0-2.206-1.794-4-4-4s-4 1.794-4 4c0 2.207 1.794 4 4 4s4-1.793 4-4zm-6 0c0-1.106.894-2 2-2 1.106 0 2 .894 2 2 0 1.107-.894 2-2 2-1.106 0-2-.893-2-2z" />
      <path d="M15.962 19a8.986 8.986 0 0 1-3.506-.706 1.004 1.004 0 0 1-.531-1.312c.212-.507.8-.744 1.312-.532A7.006 7.006 0 0 0 22.962 10c0-3.862-3.137-7-7-7A7.029 7.029 0 0 0 9.9 6.5a1 1 0 0 1-1.369.363 1 1 0 0 1-.362-1.369 9.036 9.036 0 0 1 7.793-4.5c4.963 0 9 4.038 9 9 0 4.969-4.037 9.006-9 9.006z" />
      <path d="m18.594 7.794-3.382 3.381-1.88-1.88a1.003 1.003 0 0 0-1.413 0 1.003 1.003 0 0 0 0 1.412l2.587 2.587a1.003 1.003 0 0 0 1.413 0l4.087-4.087a1.003 1.003 0 0 0 0-1.413 1.003 1.003 0 0 0-1.412 0z" />
    </SvgIcon>
  );
};

export default RoleIcon;
