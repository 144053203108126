import { SvgIcon } from '@mui/material';

const DocumentationIcon = (props) => {
  return (
    <SvgIcon viewBox="-2.5 -1 24 24" {...props}>
      <path d="m18.5 21-5.294-5.294A4.999 4.999 0 0 0 9 8c-2.763 0-5 2.238-5 5s2.237 5 5 5c.994 0 1.925-.294 2.706-.794L17 22.5l1.5-1.5zM9 16a3 3 0 1 1 .002-6.002A3 3 0 0 1 9 16z" />
      <path d="M12 20H2V2h10l4 4v10l2 2V5.831c0-.531-.212-1.037-.587-1.412L13.58.588A1.996 1.996 0 0 0 12.17 0H2C.894 0 0 .894 0 2v18c0 1.106.894 2 2 2h12l-2-2z" />
    </SvgIcon>
  );
};

export default DocumentationIcon;
