import { SvgIcon } from '@mui/material';

const JoinIcon = (props) => {
  return (
    <SvgIcon viewBox="-3 -4 24 24" {...props}>
      <path d="M2 16L2 0L0 -8.74228e-08L-6.99382e-07 16L2 16Z" />
      <path d="M14.1687 7L4 7L4 9L14.1687 9L9.5875 13.5875L11 15L18 8L11 1L9.5875 2.4125L14.1687 7Z" />
    </SvgIcon>
  );
};

export default JoinIcon;
