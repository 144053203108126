import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DdprLogo = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      {...props}
      display={{ lg: 'flex' }}
      textAlign={{ xs: 'center', lg: 'left' }}
      alignItems="center"
      gap={3}
    >
      <Box component="img" my={2} mx="auto" width={137} src="/icons/gdpr.svg" alt="gdpr" />
      <Typography variant="body2" color="text.secondary">
        {t('gdprCompliant')}
      </Typography>
    </Box>
  );
};

export default DdprLogo;
