import { SvgIcon } from '@mui/material';

const ConfirmIcon = (props) => {
  return (
    <SvgIcon viewBox="6 5 20 20" {...props}>
      <path d="m13.55 22-5.7-5.7 1.425-1.425 4.275 4.275 9.175-9.175L24.15 11.4 13.55 22Z" />
    </SvgIcon>
  );
};

export default ConfirmIcon;
