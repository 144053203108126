import { SvgIcon } from '@mui/material';

const LockOpenIcon = (props) => {
  return (
    <SvgIcon viewBox="-3.5 -1.5 24 24" {...props}>
      <path d="M15 10H5V5a3 3 0 0 1 6 0v2h2V5c0-2.763-2.238-5-5-5-2.763 0-5 2.237-5 5v5H1c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1zm-1 9H2v-7h12v7z" />
    </SvgIcon>
  );
};

export default LockOpenIcon;
