import { SvgIcon } from '@mui/material';

const TokenIcon = (props) => {
  return (
    <SvgIcon viewBox="4 4 25 25" {...props}>
      <path d="M16 4 5 10v12l11 6 11-6V10L16 4zm7.869 6.569-4.044 2.206A4.995 4.995 0 0 0 16 11a4.973 4.973 0 0 0-3.819 1.775l-4.043-2.206L16 6.28l7.869 4.288zM16 19a3 3 0 1 1 .002-6.002A3 3 0 0 1 16 19zm-9-6.769 4.219 2.3A5.002 5.002 0 0 0 15 20.9v4.275l-8-4.363v-8.58zm10 12.944V20.9a5.002 5.002 0 0 0 3.781-6.369l4.219-2.3v8.582l-8 4.362z" />
    </SvgIcon>
  );
};

export default TokenIcon;
