import { SvgIcon } from '@mui/material';

const CheckIcon = (props) => {
  return (
    <SvgIcon viewBox="-1 -4 26 26" {...props}>
      <path d="m0 9 3-3 6 6L21 0l3 3L9 18 0 9z" />
    </SvgIcon>
  );
};

export default CheckIcon;
