import { SvgIcon } from '@mui/material';

const LockIcon = (props) => {
  return (
    <SvgIcon viewBox="-3.5 -2.5 24 24" {...props}>
      <path d="M15 8h-2V5c0-2.763-2.238-5-5-5-2.763 0-5 2.237-5 5v3H1c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zM5 5a3 3 0 0 1 6 0v3H5V5zm9 12H2v-7h12v7z" />
    </SvgIcon>
  );
};

export default LockIcon;
