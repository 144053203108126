import { SvgIcon } from '@mui/material';

const CopyIcon = (props) => {
  return (
    <SvgIcon viewBox="-2 -2 24 24" {...props}>
      <path d="M16 0H6C4.89375 0 4 0.89375 4 2V4H2C0.89375 4 0 4.89375 0 6V18C0 19.1063 0.89375 20 2 20H12C13.1062 20 14 19.1063 14 18V16H16C17.1063 16 18 15.1062 18 14V2C18 0.89375 17.1063 0 16 0ZM12 18H2V6H12V18ZM16 14H14V6C14 4.89375 13.1062 4 12 4H6V2H16V14Z" />
    </SvgIcon>
  );
};

export default CopyIcon;
