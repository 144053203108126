import request from 'queries/api';

export function getTeam(id) {
  return request({
    url: `/teams/${id}`,
    method: 'GET'
  });
}

export function createTeam(data) {
  return request({
    url: '/teams',
    method: 'POST',
    data
  });
}

export function updateTeam(id, data, messages = {}) {
  return request({
    url: `/teams/${id}`,
    method: 'PATCH',
    data
  },
  {
    success: 'messages.updateTeamSuccess',
    error:   'response',
    ...messages
  });
}

export function updateCname(id, data, messages = {}) {
  return request({
    url: `/teams/${id}/cname`,
    method: 'POST',
    data
  },
  {
    success: 'messages.updateTeamSuccess',
    error:   'response',
    ...messages
  });
}

export function resetTeam(teamId) {
  return request({
    url: `/teams/${teamId}/reset`,
    method: 'POST'
  },
  {
    success: 'messages.updateTeamSuccess',
    error:   'response'
  });
}

export function joinTeam(token) {
  return request({
    url: '/teams/join',
    method: 'POST',
    data: { token }
  },
  {
    success: 'messages.joinTeamSuccess',
    error:   'response'
  });
}

export function setCurrentTeam(teamId) {
  return request({
    url: '/teams/current',
    method: 'POST',
    data: { id: teamId }
  },
  {
    error: 'messages.setCurrentTeamFail'
  });
}

export function getInvitationToken(teamId, regenerate = false) {
  return request({
    url: `/teams/${teamId}/invitation?regenerate=${regenerate}`,
    method: 'GET'
  });
}

export function checkInvitationToken(token) {
  return request({
    url: '/check-invitation-token',
    method: 'POST',
    data: { token }
  });
}

export function regenerateApiKey(teamId) {
  return request({
    url: `/teams/${teamId}/regenerate-key`,
    method: 'POST'
  },
  {
    success: 'messages.updateDeveloperKeySuccess'
  });
}
