import { CircularProgress, Box } from '@mui/material';

const CircularLoading = ({ show }) => (
  <Box
    display={show ? 'block' : 'none'}
    zIndex="2000"
    position="fixed"
    top="50%"
    left="50%"
    className="AppLoader"
    sx={{ transform: 'translate(-50%, -50%)' }}
  >
    <CircularProgress disableShrink />
  </Box>
);

export default CircularLoading;
