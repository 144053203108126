import { Stack, Container, Box } from '@mui/material';
import MainLogo from 'components/Layouts/Components/MainLogo';
import Footer from 'components/Layouts/Components/Footer';
import NotFound from 'pages/Error/Components/NotFound';
import AppError from 'pages/Error/Components/AppError';

const Error = ({ error, resetErrorBoundary }) => (
  <Stack
    className="AppPublicLayout"
    direction="column"
    justifyContent="space-between"
    alignItems="stretch"
    sx={{
      minHeight: '100%',
      backgroundColor: 'blue.dark'
    }}
  >
    <Container
      sx={{
        pt: {
          xs: 1.5, md: 2, lg: 2.25
        },
        pb: 3
      }}
    >
      <Stack direction="row" justifyContent="center" alignItems="center">
        <MainLogo dark />
      </Stack>
    </Container>
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="center" my={5}>
        {error.status === 404 ? (
          <NotFound />
        ) : (
          <AppError error={error} resetError={resetErrorBoundary} />
        )}
      </Box>
    </Container>
    <Footer dark />
  </Stack>
);

export default Error;
