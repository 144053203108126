import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from 'context/AuthContext';

const useAdmin = () => {
  const { auth: { role } } = useContext(AuthContext);
  const { pathname }  = useLocation();

  return {
    adminRole:    (role !== 'REGULAR') ? role : null,
    isAdmin:      (role !== 'REGULAR'),
    isSuperAdmin: (role === 'ADMIN'),
    isSales:      (role === 'SALES'),
    isDev:        (role === 'DEV'),
    isAdminPage:  (pathname.indexOf('/admin') !== -1)
  };
};

export default useAdmin;
