import { Box, Link } from '@mui/material';

const MainLogo = ({ dark = false }) => (
  <Link underline="none" href="/">
    <Box
      component="img"
      height={{ xs: '40px', md: '50px', xxl: '60px' }}
      src={`${process.env.PUBLIC_URL}/${dark ? 'logo-light' : 'logo'}.svg`}
      alt=""
    />
  </Link>
);

export default MainLogo;
