import request from 'queries/api';

export function getState() {
  return request({
    url: '/state',
    method: 'GET'
  });
}

export function getProfile() {
  return request({
    url: '/profile',
    method: 'GET'
  });
}

export function finalizeOnboarding() {
  return request({
    url: '/finalize-onboarding',
    method: 'POST'
  });
}

export function completeProfile(data) {
  return request({
    url: '/profile',
    method: 'POST',
    data
  });
}

export function update(data, messages = {}) {
  return request({
    url: '/profile',
    method: 'PATCH',
    data
  },
  {
    success: 'messages.updateAccountSuccess',
    ...messages
  });
}

export function updatePassword() {
  return request({
    url: '/reset-password',
    method: 'POST'
  });
}

export function deleteAccount() {
  return request({
    url: '/account',
    method: 'DELETE'
  },
  {
    success: 'messages.deleteAccountSuccess'
  });
}

export function impersonate(data) {
  return request({
    url: '/impersonate',
    method: 'POST',
    data
  });
}

export function unimpersonate() {
  return request({
    url: '/unimpersonate',
    method: 'POST'
  });
}

export function checkoutLink(plan) {
  return request({
    url: '/subscriptions/checkout-link',
    method: 'POST',
    data: {
      plan
    }
  }, {
    error: 'response'
  });
}

export function upgradeLink(data) {
  return request({
    url: '/subscriptions/upgrade-link',
    method: 'POST',
    data
  }, {
    error: 'response'
  });
}

export function getSubscription() {
  return request({
    url: '/subscriptions/current',
    method: 'GET'
  }, {
    error: 'response'
  });
}

export function downgradeSubscription(data) {
  return request({
    url: '/subscriptions/downgrade',
    method: 'POST',
    data
  }, {
    error: 'response'
  });
}

export function cancelSubscription() {
  return request({
    url: '/subscriptions/cancel',
    method: 'POST'
  }, {
    error: 'response'
  });
}

export function getQr() {
  return request({
    url: '/2fa/qr',
    method: 'GET'
  }, {
    error: 'response'
  });
}

export function validateQr(data) {
  return request({
    url: '/2fa/qr',
    method: 'POST',
    data
  }, {
    success: 'messages.twoFactorAuthEnabled'
  });
}

export function validateCode(data) {
  return request({
    url: '/2fa/validate',
    method: 'POST',
    data
  });
}

export function restoreAuth(browserId) {
  return request({
    url: '/2fa/restore',
    method: 'POST',
    data: {
      browser_id: browserId
    }
  });
}
