import axios from 'axios';
import i18n from 'i18next';
import { enqueueSnackbar } from 'notistack';

const client = axios.create({
  baseURL: process.env.REACT_APP_MONZA_API,
  responseType: 'json',
  withCredentials: true
});

const enqueueMessage = async (messages, variant, responseMessage = '') => {
  const message = messages?.[variant];

  if (!message) {
    return false;
  }

  enqueueSnackbar(
    (message === 'response') ? (responseMessage || i18n.t('messages.unknownError')) : i18n.t(message),
    { variant }
  );
};

const request = (request, messages = {}) => {
  return client.request(request)
    .then((result) => {
      enqueueMessage(messages, 'success');
      return result.data;
    })
    .catch((result) => {
      const { response: { status, data } = {} } = result;

      if (!data) {
        enqueueMessage({ error: 'messages.serverUnavailable' }, 'error');
      } else {
        enqueueMessage(messages, 'error', data.message);
      }

      throw data ? {
        status,
        message: data.message,
        error: data.error,
        details: data.error_details
      } : result;
    });
};

export default request;
