import { Outlet } from 'react-router-dom';
import { Box, Stack, Container } from '@mui/material';
import Header from 'components/Layouts/Components/Header';
import Footer from 'components/Layouts/Components/Footer';
import SideMenu from 'components/Layouts/Components/Menus/SideMenu';
import Buttons from 'components/Layouts/Components/Buttons';

const MainLayout = ({ fullWidth = false }) => {
  return (
    <Stack
      className="AppMainLayout"
      direction="column"
      sx={{
        minHeight: '100%',
        minWidth: 680
      }}
    >
      <Header />
      <Box display="flex" flex={1}>
        {!fullWidth && (
          <Box mr={{ lg: 4, xl: 5 }}>
            <SideMenu />
            <Buttons />
          </Box>
        )}
        <Container
          className="App-content"
          maxWidth={false}
          sx={{
            pb: 3,
            minWidth: 500
          }}
        >
          <Outlet />
        </Container>
      </Box>
      <Footer />
    </Stack>
  );
};

export default MainLayout;
