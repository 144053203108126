import { Navigate, Outlet } from 'react-router-dom';
import { useAdmin, useAuth } from 'hooks';

const Admin = ({ roles }) => {
  const { auth } = useAuth();
  const { adminRole, isAdmin, isAdminPage } = useAdmin();

  if (isAdminPage) {
    if (!isAdmin) {
      return <Navigate to="/" />;
    }
    if (roles && !roles.includes(adminRole)) {
      return <Navigate to="/" />;
    }
    if (!auth.two_factor_auth_confirmed) {
      return <Navigate to="/admin-auth" />;
    }
  }
  return <Outlet />;
};

export default Admin;
