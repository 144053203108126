import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  Box, Typography, Stack, Button, IconButton, Popover, Divider
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { useAuth, useAdmin } from 'hooks';
import LogoutLink from 'components/Common/LogoutLink';
import RandomColorAvatar from 'components/Common/RandomColorAvatar';

const AccountMenu = (props) => {
  const { auth }                  = useAuth();
  const { pathname }              = useLocation();
  const { t }                     = useTranslation();
  const { isAdmin, isAdminPage }  = useAdmin();
  const [anchor, setAnchor]       = useState(null);

  const handleOpenMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <Box {...props}>
      <IconButton disableRipple onClick={handleOpenMenu} sx={{ p: 0 }}>
        <RandomColorAvatar title={auth.email} />
        <ArrowDropDown />
      </IconButton>
      <Popover
        keepMounted
        id="menu-account"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClick={handleCloseMenu}
        onClose={handleCloseMenu}
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Stack px={4} pt={3} pb={2} justifyContent="center" alignItems="center" gap={1}>
          <RandomColorAvatar sx={{ width: 55, height: 55 }} title={auth.email} />
          <Box py={0.75} textAlign="center">
            <Typography color="text.black" fontWeight={600} variant="body1">
              {t('welcome')}, <Box component="span" className="ph-no-capture">{auth.first_name} {auth.last_name}</Box>
            </Typography>
            <Typography color="text.secondary" className="ph-no-capture">{auth.email}</Typography>
          </Box>
          <Button fullWidth variant="outlined" component={Link} state={{ from: pathname }} to="/my-account">{t('manageYourAccount')}</Button>
          {(isAdmin && !auth.impersonate) && (
            <Button fullWidth variant="outlined" component={Link} to={isAdminPage ? '' : '/admin/statistics'}>
              {t(isAdminPage ? 'adminExit' : 'admin')}
            </Button>
          )}
        </Stack>
        <Divider />
        <Box textAlign="center" py={1.25}>
          <LogoutLink underline="none" color="text.black">{t('logout')}</LogoutLink>
        </Box>
      </Popover>
    </Box>
  );
};
export default AccountMenu;
