import { useParams, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useErrorBoundary } from 'react-error-boundary';
import { useAuth } from 'hooks';
import { getTeam } from 'queries/teams';

const TeamId = () => {
  const { teamId } = useParams();
  const { showBoundary } = useErrorBoundary();
  const { auth, setAuth } = useAuth();
  const hasTeam = !!auth.teams.find((team) => team.id === teamId);

  const { data: team } = useQuery({
    queryKey: ['team', teamId],
    queryFn: () => getTeam(teamId),
    keepPreviousData: true,
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    enabled: hasTeam,
    onSuccess: (data) => setAuth({
      ...auth,
      last_team: data
    })
  });

  if (!hasTeam) {
    showBoundary({ status: 404 });
  }

  return team && (
    <Outlet context={{
      team,
      teamId
    }}
    />
  );
};

export default TeamId;
