import { Typography, Box } from '@mui/material';

const IconLabel = (
  {
    children, icon, label = null, iconPosition = 'left', variant = 'body2', sx = {}, ...rest
  }
) => {
  const props = {
    display: 'flex',
    alignItems: 'center',
    sx: { gap: 1, ...sx },
    variant,
    ...rest
  };

  return (
    <Typography component="div" {...props}>
      {iconPosition !== 'right' && icon}
      <Box component="span">{children || label}</Box>
      {iconPosition === 'right' && icon}
    </Typography>
  );
};

export default IconLabel;
