import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import CheckIcon from 'components/Icons/Svg/CheckIcon';
import IconLabel from 'components/Icons/IconLabel';

const CustomersSlider = lazy(() => import('components/Layouts/Components/CustomersSlider'));

const ProductSummary = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const pageTitle = () => {
    if (pathname.indexOf('/login') === 0) {
      return t('developersLogin');
    }
    return t('developersSignup');
  };

  return (
    <Box my={{ md: 2, xxl: 5 }}>
      <Typography color="blue.contrastText" variant="h5">
        {t('productName')} | {pageTitle()}
      </Typography>
      <Typography
        className="ProductHeader"
        color="white"
        variant="h2"
        mt={{ md: 4, xl: 6, xxl: 10 }}
        mb={{ md: 4, xxl: 5.5 }}
      >
        {t('productDescriptionTitle')}
      </Typography>
      <Box ml={1}>
        <IconLabel mb={2} sx={{ gap: 2 }} color="white" variant="h6" icon={<CheckIcon />}>{t('productDescription.0')}</IconLabel>
        <IconLabel my={2} sx={{ gap: 2 }} color="white" variant="h6" icon={<CheckIcon />}>{t('productDescription.1')}</IconLabel>
        <IconLabel my={2} sx={{ gap: 2 }} color="white" variant="h6" icon={<CheckIcon />}>{t('productDescription.2')}</IconLabel>
        <IconLabel my={2} sx={{ gap: 2 }} color="white" variant="h6" icon={<CheckIcon />}>{t('productDescription.3')}</IconLabel>
        <IconLabel mt={2} sx={{ gap: 2 }} color="white" variant="h6" icon={<CheckIcon />}>{t('productDescription.4')}</IconLabel>
      </Box>
      <Typography
        color="blue.contrastText"
        variant="h6"
        mt={{ md: 3, xl: 5, xxl: 14.5 }}
        mb={2}
      >
        {t('ourCustomers')}
      </Typography>
      <Suspense fallback={null}>
        <CustomersSlider />
      </Suspense>
    </Box>
  );
};

export default ProductSummary;
