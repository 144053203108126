import { SvgIcon } from '@mui/material';

const RecordingIcon = (props) => {
  return (
    <SvgIcon viewBox="-1 -1 24 24" {...props}>
      <path d="M10.938 6A4.94 4.94 0 0 0 6 10.938a4.94 4.94 0 0 0 4.938 4.937 4.94 4.94 0 0 0 4.937-4.938A4.94 4.94 0 0 0 10.937 6zM11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zm0 20c-4.969 0-9-4.031-9-9s4.031-9 9-9 9 4.031 9 9-4.031 9-9 9z" />
    </SvgIcon>
  );
};

export default RecordingIcon;
