import { SvgIcon } from '@mui/material';

const BarChartIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -2 24 24" {...props}>
      <path d="M22 19H0v-2h2V9c0-1.106.894-2 2-2h2c1.106 0 2 .894 2 2v8h1V2c0-1.106.894-2 2-2h2c1.106 0 2 .894 2 2v15h1V6c0-1.106.894-2 2-2h2c1.106 0 2 .894 2 2v11h2v2h-2zm-2-2V6h-2v11h2zm-7 0V2h-2v15h2zm-7 0V9H4v8h2z" />
    </SvgIcon>
  );
};

export default BarChartIcon;
