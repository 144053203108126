import { useEffect } from 'react';

const useTitle = (title = '') => {
  const documentDefined = typeof document !== 'undefined';

  useEffect(() => {
    if (!documentDefined) return;

    if (document.title !== title) {
      document.title = title;
    }
  }, [documentDefined, title]);
};

export default useTitle;
