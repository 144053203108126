import { SvgIcon } from '@mui/material';

const AddIcon = (props) => {
  return (
    <SvgIcon viewBox="-2 -2 20 20" {...props}>
      <path d="M16 9H9V16H7V9H0V7H7V0H9V7H16V9Z" />
    </SvgIcon>
  );
};

export default AddIcon;
