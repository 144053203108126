import {
  useCallback, useRef, useEffect, useState
} from 'react';

const useElementPosition = () => {
  const ref   = useRef();
  const [size, setSize] = useState({
    top: 0,
    left: 0
  });

  const handleSize = useCallback(() => {
    if (ref === null) {
      return false;
    }
    setSize({
      top: ref.current?.offsetTop || 0,
      left: ref.current?.offsetLeft || 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.offsetTop, ref.current?.offsetLeft]);

  useEffect(() => {
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, [handleSize]);

  useEffect(() => {
    handleSize();
  }, [handleSize]);

  return [ref, size];
};

export default useElementPosition;
