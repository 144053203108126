import { SvgIcon } from '@mui/material';

const WebhookIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 26 24" {...props}>
      <path d="M21 20c1.106 0 2-.894 2-2 0-1.106-.894-2-2-2-.738 0-1.387.4-1.731 1H9c-.55 0-1 .45-1 1a3 3 0 0 1-6 0c0-1.331.856-2.481 2.125-2.869a.996.996 0 0 0 .662-1.25.996.996 0 0 0-1.25-.662A5.01 5.01 0 0 0 1 15a5.007 5.007 0 0 0 4 8.006 5.007 5.007 0 0 0 4.9-4h9.369c.344.594.994.994 1.731.994zM3 18c0 1.106.894 2 2 2 1.106 0 2-.894 2-2 0-.344-.088-.669-.237-.95l4.993-8.119a1.001 1.001 0 0 0-.325-1.375 3 3 0 1 1 4.525-2.044 1.004 1.004 0 0 0 .819 1.157 1.004 1.004 0 0 0 1.156-.819c.05-.281.075-.569.075-.85 0-2.756-2.244-5-5-5s-5 2.244-5 5c0 1.406.575 2.713 1.582 3.65L5.056 16H5c-1.106 0-2 .894-2 2zm18-5c-.587 0-1.169.106-1.719.3l-4.525-7.35c.156-.281.238-.606.238-.95 0-1.106-.894-2-2-2-1.107 0-2 .894-2 2 0 1.106.893 2 2 2h.056l4.994 8.119a1.003 1.003 0 0 0 1.375.331 3 3 0 1 1 1.569 5.556 2.993 2.993 0 0 1-1.982-.75 1.006 1.006 0 0 0-1.412.088 1.006 1.006 0 0 0 .087 1.412 4.991 4.991 0 0 0 3.306 1.25c2.757 0 5-2.244 5-5S23.758 13 21 13z" />
    </SvgIcon>
  );
};

export default WebhookIcon;
