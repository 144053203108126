import { SvgIcon } from '@mui/material';

const EditIcon = (props) => {
  return (
    <SvgIcon viewBox="-4 -4 22 22" {...props}>
      <path d="m12.5 3.5 1.207-1.205a1.003 1.003 0 0 0 0-1.413l-.588-.587a1.003 1.003 0 0 0-1.412 0L10.5 1.5l2 2zM11.5 4.5l-2-2-8.594 8.595a.995.995 0 0 0-.243.393l-.638 1.919a.454.454 0 0 0 .575.575l1.919-.637a.994.994 0 0 0 .394-.244L11.5 4.5z" />
    </SvgIcon>
  );
};

export default EditIcon;
