import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CameraIcon } from 'components/Icons/Svg';
import { createRoom } from 'queries/rooms';
import { roomUrl } from 'helpers';

const MeetNow = ({ team }) => {
  const { t }       = useTranslation();
  const queryClient = useQueryClient();

  const createQuery = useMutation({
    mutationFn: () => createRoom(team.id, {
      privacy: 'public'
    }, {
      success: 'messages.meetNowSuccess',
      error: null
    }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['rooms'], refetchType: 'all'
      });
      window.open(roomUrl(team, data), '_blank');
    }
  });

  const handleStart = () => createQuery.mutate();

  return (
    <Button disabled={createQuery.isLoading} onClick={handleStart}>
      <CameraIcon
        fontSize="small"
        sx={{
          ml: -0.5,
          mr: 1,
          display: {
            xs: 'none',
            md: 'inline'
          }
        }}
      />
      {t('meetNow')}
    </Button>
  );
};

export default MeetNow;
