import { useTranslation } from 'react-i18next';
import { Typography, Link, Box } from '@mui/material';
import moment from 'moment/moment';
import { useEnv } from 'hooks';

const Copyrights = ({ color, ...rest }) => {
  const { t } = useTranslation();
  const privacyUrl  = useEnv('PRIVACY_URL');
  const textColor = color || 'text.secondary';
  return (
    <Typography color={textColor} {...rest}>
      <Box component="span">{t('copyrights', { year: moment().format('YYYY') })}</Box>
      <Box component="span" mx={1.5}>|</Box>
      <Link color={textColor} href={privacyUrl} target="_blank" rel="noreferrer">{t('privacyPolicy')}</Link>
    </Typography>
  );
};

export default Copyrights;
