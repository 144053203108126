import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { ArrowBackIos } from '@mui/icons-material';
import { Button } from '@mui/material';
import { unimpersonate } from 'queries/account';

const Impersonation = ({ team }) => {
  const { t } = useTranslation();

  const impersonateQuery = useMutation({
    mutationFn: unimpersonate,
    onSuccess: () => {
      document.location.href = `/admin/teams/${team.id}`;
    }
  });

  const handleUnimpersonate = () => impersonateQuery.mutate();

  return (
    <Button
      startIcon={<ArrowBackIos />}
      variant="text"
      onClick={handleUnimpersonate}
    >
      {t('impersonateExit')}
    </Button>
  );
};

export default Impersonation;
