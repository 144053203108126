import { SvgIcon } from '@mui/material';

const SyncIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -3 24 24" {...props}>
      <path d="m20 5-4 4h3c0 3.869-3.131 7-7 7a6.977 6.977 0 0 1-4.95-2.05l-1.412 1.413A8.949 8.949 0 0 0 12 18c4.969 0 9-4.031 9-9h3l-4-4zM5 9c0-3.869 3.131-7 7-7 1.931 0 3.681.781 4.95 2.05l1.413-1.412A8.949 8.949 0 0 0 12 0C7.031 0 3 4.031 3 9H0l4 4 4-4H5z" />
    </SvgIcon>
  );
};

export default SyncIcon;
