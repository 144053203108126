import { SvgIcon } from '@mui/material';

const CircleIcon = (props) => {
  return (
    <SvgIcon viewBox="-6 -6 24 24" {...props}>
      <circle cx="6" cy="6" r="5" stroke="#fff" strokeWidth="2" />
    </SvgIcon>
  );
};

export default CircleIcon;
