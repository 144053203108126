import { SvgIcon } from '@mui/material';

const CameraIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -5 21 21" {...props}>\
      <path d="m18 11-3-3v2c0 1.106-.894 2-2 2H2c-1.106 0-2-.894-2-2V2C0 .894.894 0 2 0h11c1.106 0 2 .894 2 2v2l3-3h2v10h-2z" />
    </SvgIcon>
  );
};

export default CameraIcon;
