import request from 'queries/api';

export function login(data) {
  return request({
    url: '/login',
    method: 'POST',
    data
  });
}

export function verifyEmail(data) {
  return request({
    url: '/login',
    method: 'POST',
    data
  });
}

export function logout(data = null) {
  return request({
    url: '/logout',
    method: 'POST',
    data
  });
}

export function signup(data) {
  return request({
    url: '/signup',
    method: 'POST',
    data
  });
}

export function sendVerificationEmail() {
  return request({
    url: '/send-verification-email',
    method: 'POST'
  },
  {
    error:   'response',
    success: 'messages.sendVerificationEmailSuccess'
  });
}

export function forgotPassword(email) {
  return request({
    url: '/forgot-password',
    method: 'POST',
    data: { email }
  });
}

export function checkPasswordToken(token) {
  return request({
    url: '/check-password-token',
    method: 'POST',
    data: { token }
  });
}

export function updatePassword(data) {
  return request({
    url: '/update-password',
    method: 'POST',
    data
  });
}

export function setupPassword(data) {
  return request({
    url: '/setup-password',
    method: 'POST',
    data
  });
}

export function oauth(queryString) {
  document.location.href = `${process.env.REACT_APP_MONZA_API}/oauth/authorize?${queryString}`;
}
