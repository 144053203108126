import { StrictMode } from 'react';
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const phOptions = {
  api_host: process.env.REACT_APP_POSTHOG_HOST
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    {process.env.REACT_APP_POSTHOG_KEY ? (
      <PostHogProvider
        apiKey={process.env.REACT_APP_POSTHOG_KEY}
        options={phOptions}
      >
        <App />
      </PostHogProvider>
    ) : <App />}
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
