import { SvgIcon } from '@mui/material';

const SettingsIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 22" {...props}>
      <path d="m20 11 3.007-.925-.35-1.969-3.138.156a8.09 8.09 0 0 0-1.387-2.406l1.706-2.643-1.531-1.288L16 4.069a7.982 7.982 0 0 0-2.612-.95L13 0h-2l-.387 3.119A7.981 7.981 0 0 0 8 4.069L5.694 1.93 4.163 3.22l1.706 2.643A7.884 7.884 0 0 0 4.482 8.27l-3.138-.156-.35 1.968L4 11c0 .963.17 1.881.482 2.738l-2.507 1.9 1 1.73 2.9-1.224A8.109 8.109 0 0 0 8 17.93L7.3 21l1.882.681 1.43-2.8A7.8 7.8 0 0 0 12 19c.475 0 .938-.044 1.388-.119l1.431 2.8L16.7 21l-.7-3.069a8.01 8.01 0 0 0 2.125-1.787l2.9 1.225 1-1.732-2.506-1.9A7.955 7.955 0 0 0 20 11zm-8 5.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11z" />
    </SvgIcon>
  );
};

export default SettingsIcon;
