import { SvgIcon } from '@mui/material';

const SystemIcon = (props) => {
  return (
    <SvgIcon viewBox="3 4 26 26" {...props}>
      <path d="M21 26H11a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2zm4-21H7c-1.106 0-2 .894-2 2v15c0 1.106.894 2 2 2h18c1.106 0 2-.894 2-2V7c0-1.106-.894-2-2-2zm0 17H7V7h18v15zm-10.706-3.706a1.004 1.004 0 0 0 1.412 0l6.044-6.044a1.064 1.064 0 0 0 0-1.5 1.064 1.064 0 0 0-1.5 0L15 16l-2.25-2.25a1.064 1.064 0 0 0-1.5 0 1.064 1.064 0 0 0 0 1.5l3.044 3.044z" />
    </SvgIcon>
  );
};

export default SystemIcon;
