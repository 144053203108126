import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const nameParts = name.split(' ');
  let children = nameParts[0][0];

  if (nameParts[1]) {
    children += nameParts[1][0];
  }

  return children.toUpperCase();
}

const RandomColorAvatar = ({ title, sx = {} }) => {
  const styles = {
    bgcolor: stringToColor(title),
    width: {
      xs: 33,
      md: 40
    },
    height: {
      xs: 33,
      md: 40
    },
    ...sx
  };
  return <Avatar sx={styles}>{stringAvatar(title)}</Avatar>;
};

export default RandomColorAvatar;
