import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'i18n/locales/en/translations';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: en
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  languages: ['en'],
  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
  interpolation: { escapeValue: false },
  useSuspense: false
});

i18n.services.formatter.add('lowercase', (value) => {
  return value.toLowerCase();
});

i18n.services.formatter.add('uppercase', (value) => {
  return value.toUpperCase();
});

i18n.services.formatter.add('capitalize', (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

export default i18n;
