import { Container, Box, Stack } from '@mui/material';
import { useAuth, useAdmin } from 'hooks';
import TeamsMenu from 'components/Layouts/Components/Menus/TeamsMenu';
import AccountMenu from 'components/Layouts/Components/Menus/AccountMenu';
import Impersonation from 'components/Layouts/Components/Impersonation';
import MainLogo from 'components/Layouts/Components/MainLogo';
import MeetNow from 'components/Layouts/Components/MeetNow';

const Header = ({ dark = false, publicPage = false, ...rest }) => {
  const { auth }        = useAuth();
  const { isAdminPage } = useAdmin();
  const containerProps  = {
    sx: {
      pt: {
        xs: 1.5, md: 2, lg: 2.5
      },
      pb: publicPage ? 3 : {
        xs: 3, md: 4, xxl: 8
      },
      mx: 0
    },
    maxWidth: false,
    ...rest
  };

  return (
    <Container {...containerProps}>
      <Stack direction="row" justifyContent={publicPage ? 'center' : 'space-between'} alignItems="center">
        <MainLogo dark={dark} />
        {(!publicPage && auth.onboarding_finished) && (
          <Box
            display="flex"
            alignItems="center"
            gap={{
              xs: 2, sm: 3, md: 5, lg: 7
            }}
            ml={3}
          >
            {!isAdminPage && (
              auth.impersonate ? (
                <Impersonation team={auth.last_team} />
              ) : (
                <>
                  <MeetNow team={auth.last_team} />
                  <TeamsMenu />
                </>
              )
            )}
            <AccountMenu />
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default Header;
