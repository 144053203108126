import { useTheme } from '@mui/material/styles';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Stack, Box, useMediaQuery } from '@mui/material';
import DdprLogo from 'components/Layouts/Components/DdprLogo';
import Copyrights from 'components/Layouts/Components/Copyrights';
import ProductSummary from 'components/Layouts/Components/ProductSummary';
import MainLogo from 'components/Layouts/Components/MainLogo';

const SignupLayout = () => {
  const context           = useOutletContext();
  const { breakpoints }   = useTheme();
  const isMobile          = useMediaQuery(breakpoints.down('md'));

  const stackProps = {
    mx: 'auto',
    width: '100%',
    maxWidth: { md: 750 },
    px: { xs: 2, md: 6 },
    py: { xs: 2, xl: 3, xxl: 5 },
    sx: { boxSizing: 'border-box' }
  };

  return (
    <Stack
      className="AppSignupLayout"
      alignItems="stretch"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ minHeight: '100%' }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="stretch"
        sx={{
          width: { xs: '100%',  md: '50%' },
          minHeight: '100%',
          backgroundColor: { md: 'blue.dark' }
        }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="stretch"
          {...stackProps}
        >
          <Box textAlign={{ xs: 'center', md: 'left' }}>
            <MainLogo dark={!isMobile} />
          </Box>
          {!isMobile && (
            <>
              <ProductSummary />
              <Copyrights variant="body2" color="blue.contrastText" />
            </>
          )}
        </Stack>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="stretch"
        sx={{ flex: 1, width: { xs: '100%',  md: '50%' } }}
      >
        <Stack
          alignContent="stretch"
          alignItems="stretch"
          {...stackProps}
        >
          <Box my="auto" mx={{ xs: 'auto', xxl: 0 }} maxWidth={450}>
            <Outlet context={context} />
          </Box>
          <DdprLogo />
        </Stack>
      </Box>
    </Stack>
  );
};

export default SignupLayout;
