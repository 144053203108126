import { lazy, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import {
  Admin, Auth, CurrentTeam, NonAuth, VerifyToken, TeamId, Trackers
} from 'routes/Middleware';

import PublicLayout from 'components/Layouts/PublicLayout';
import MainLayout from 'components/Layouts/MainLayout';
import SignupLayout from 'components/Layouts/SignupLayout';
import StickyLayout from 'components/Layouts/StickyLayout';
import CircularLoading from 'components/Common/CircularLoading';

const lazyLoad = (path) => {
  const Page = lazy(() => import(`pages/${path}`));

  return (
    <Suspense fallback={<CircularLoading show />}>
      <Page />
    </Suspense>
  );
};

const Container = () => {
  return (
    <BrowserRouter>
      <Trackers>
        <Routes>
          <Route element={<PublicLayout />}>
            <Route path="/oauth" element={lazyLoad('Login/Oauth')} />
            <Route path="/forgot-password/:token?" element={lazyLoad('ResetPassword/ForgotPassword')} />
            <Route path="/update-password/:token" element={lazyLoad('ResetPassword/UpdatePassword')} />
            <Route path="/verify-email/:token" element={lazyLoad('OnBoarding/VerifyEmail')} />
          </Route>
          <Route element={<PublicLayout dark />}>
            <Route path="*" element={lazyLoad('NotFound')} />
          </Route>
          <Route element={<PublicLayout maxWidth="xl" whiteLabel />}>
            <Route path="/systemcheck" element={lazyLoad('SystemCheck/SystemCheck')} />
            <Route path="/network-info" element={lazyLoad('Network/Network')} />
          </Route>
          <Route element={<VerifyToken />}>
            <Route element={<PublicLayout />}>
              <Route path="/join/:token" element={lazyLoad('JoinTeam/JoinTeam')} />
            </Route>
            <Route element={<NonAuth />}>
              <Route element={<SignupLayout />}>
                <Route path="/login/:token" element={lazyLoad('Login/Login')} />
                <Route path="/signup/:token" element={lazyLoad('SignUp/SignUp')} />
              </Route>
            </Route>
          </Route>
          <Route element={<NonAuth />}>
            <Route element={<SignupLayout />}>
              <Route path="/" element={lazyLoad('Login/Login')} />
              <Route path="/login" element={lazyLoad('Login/Login')} />
              <Route path="/signup" element={lazyLoad('SignUp/SignUp')} />
              <Route path="/setup-account/:token" element={lazyLoad('SetupAccount/SetupAccount')} />
            </Route>
          </Route>
          <Route element={<Auth />}>
            <Route element={<SignupLayout />}>
              <Route path="/thank-you" element={lazyLoad('SignUp/ThankYou')} />
            </Route>
            <Route element={<PublicLayout />}>
              <Route path="/admin-auth" element={lazyLoad('Auth/Auth')} />
              <Route path="/verify-email" element={lazyLoad('OnBoarding/VerifyEmail')} />
              <Route path="/choose-subdomain" element={lazyLoad('OnBoarding/ChooseSubdomain')} />
              <Route path="/complete-account" element={lazyLoad('OnBoarding/CompleteAccount')} />
            </Route>
            <Route element={<MainLayout />}>
              <Route path="/statistics" element={lazyLoad('Dashboard/Dashboard')} />
              <Route path="/documentation" element={lazyLoad('Documentation/Documentation')} />
              <Route path="/upgrade" element={lazyLoad('Upgrade/Upgrade')} />
              <Route path="/team" element={lazyLoad('Team/CurrentTeam')} />
              <Route path="/team/:teamId" element={lazyLoad('Team/Team')} />
              <Route element={<CurrentTeam />}>
                <Route path="/rooms" element={CircularLoading} />
                <Route path="/sessions" element={CircularLoading} />
                <Route path="/recordings" element={CircularLoading} />
                <Route path="/webhooks" element={CircularLoading} />
                <Route path="/roles" element={CircularLoading} />
              </Route>
              <Route path="/teams/:teamId" element={<TeamId />}>
                <Route path="tokens" element={lazyLoad('Tokens/Tokens')} />
                <Route path="rooms" element={lazyLoad('Rooms/Rooms')} />
                <Route path="rooms/:roomId/sessions" element={lazyLoad('Sessions/RoomSessions')} />
                <Route path="rooms/:roomId/recordings" element={lazyLoad('Recordings/RoomRecordings')} />
                <Route path="sessions" element={lazyLoad('Sessions/Sessions')} />
                <Route path="libraries" element={lazyLoad('Libraries/Libraries')} />
                <Route path="libraries/create" element={lazyLoad('Libraries/CreateLibrary')} />
                <Route path="libraries/:libraryId" element={lazyLoad('Libraries/ViewLibrary')} />
                <Route path="libraries/:libraryId/folders/create" element={lazyLoad('Libraries/CreateFolder')} />
                <Route path="libraries/:libraryId/folders/:folderId" element={lazyLoad('Libraries/ViewFolder')} />
                <Route path="sessions/:sessionId" element={lazyLoad('SessionDetails/SessionDetails')} />
                <Route path="recordings" element={lazyLoad('Recordings/Recordings')} />
                <Route path="recordings/archive" element={lazyLoad('RecordingsArchive/RecordingsArchive')} />
                <Route path="webhooks" element={lazyLoad('Webhooks/Webhooks')} />
                <Route path="roles" element={lazyLoad('Roles/Roles')} />
              </Route>
              <Route path="/admin" element={<Admin />}>
                <Route path="statistics" element={lazyLoad('Dashboard/AdminDashboard')} />
                <Route path="surveys" element={lazyLoad('Surveys/Surveys')} />
              </Route>
              <Route path="/admin" element={<Admin roles={['ADMIN', 'SALES']} />}>
                <Route path="teams" element={lazyLoad('Teams/Teams')} />
                <Route path="teams/:teamId" element={lazyLoad('Dashboard/TeamDashboard')} />
                <Route path="accounts" element={lazyLoad('Accounts/Accounts')} />
              </Route>
              <Route path="/admin/users" element={<Admin roles={['ADMIN']} />}>
                <Route path="" element={lazyLoad('Users/Users')} />
              </Route>
            </Route>
            <Route element={<StickyLayout />}>
              <Route path="/default-room-settings" element={lazyLoad('Settings/Settings')} />
              <Route path="/teams/:teamId" element={<TeamId />}>
                <Route path="rooms/create" element={lazyLoad('Rooms/CreateRoom')} />
                <Route path="rooms/:roomId" element={lazyLoad('Rooms/UpdateRoom')} />
                <Route path="roles/create" element={lazyLoad('Roles/CreateRole')} />
                <Route path="roles/:roleId" element={lazyLoad('Roles/UpdateRole')} />
                <Route path="sessions/:sessionId/:participantId" element={lazyLoad('ParticipantDetails/ParticipantDetails')} />
                <Route path="webhooks/create" element={lazyLoad('Webhooks/CreateWebhook')} />
                <Route path="webhooks/:webhookId" element={lazyLoad('Webhooks/UpdateWebhook')} />
              </Route>
            </Route>
            <Route element={<MainLayout fullWidth />}>
              <Route path="/my-account" element={lazyLoad('MyAccount/MyAccount')} />
            </Route>
          </Route>
        </Routes>
      </Trackers>
    </BrowserRouter>
  );
};

export default Container;
