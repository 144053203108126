import queryString from 'query-string';
import request from 'queries/api';

export function getRoom(team, room) {
  return request({
    url: `/teams/${team}/rooms/${room}`,
    method: 'GET'
  });
}

export function getDemoRoom(team) {
  return request({
    url: queryString.stringifyUrl({
      url: `/teams/${team}/rooms`,
      query: { demo: 1 }
    }),
    method: 'GET'
  });
}

export function getRooms(team, query = {}) {
  return request({
    url: queryString.stringifyUrl({
      url: `/teams/${team}/rooms`,
      query
    }),
    method: 'GET'
  });
}

export function getRoomTokens(team, room) {
  return request({
    url: `/teams/${team}/rooms/${room}/tokens`,
    method: 'GET'
  });
}

export function createRoom(team, data, messages = {}) {
  return request({
    url: `/teams/${team}/rooms`,
    method: 'POST',
    data
  }, {
    success: 'messages.createRoomSuccess',
    error:   'response',
    ...messages
  });
}

export function updateRoom(team, room, data) {
  return request({
    url: `/teams/${team}/rooms/${room}`,
    method: 'PATCH',
    data
  }, {
    success: 'messages.updateRoomSuccess',
    error:   'response'
  });
}

export function cloneRoom(team, room) {
  return request({
    url: `/teams/${team}/rooms/${room}/clone`,
    method: 'POST'
  }, {
    success: 'messages.cloneRoomSuccess',
    error:   'response'
  });
}

export function deleteRoom(team, room) {
  return request({
    url: `/teams/${team}/rooms/${room}`,
    method: 'DELETE'
  }, {
    success: 'messages.deleteRoomSuccess',
    error:   'messages.deleteRoomFail'
  });
}

export function deleteRooms(team, roomIds) {
  return request({
    url: `/teams/${team}/rooms`,
    method: 'DELETE',
    data: {
      ids: roomIds
    }
  }, {
    success: 'messages.deleteRoomSuccess',
    error:   'messages.deleteRoomFail'
  });
}

export function endSession(team, room) {
  return request({
    url: `/teams/${team}/rooms/${room}/end-session`,
    method: 'GET'
  }, {
    success: 'messages.endSessionSuccess',
    error:   'response'
  });
}

export function getExport(team, room, type, query = {}) {
  document.location.href = process.env.REACT_APP_MONZA_API + queryString.stringifyUrl({
    url: `/teams/${team}/rooms/${room}/${type}`,
    query
  });
}

export function getChatExport(team, room, query = {}) {
  return getExport(team, room, 'chat', query);
}

export function getQaExport(team, room, query = {}) {
  return getExport(team, room, 'questions', query);
}

export function getTranscriptsExport(team, room, query = {}) {
  return getExport(team, room, 'transcripts', query);
}

export function getPollsExport(team, room, query = {}) {
  return getExport(team, room, 'polls', query);
}
