import { SvgIcon } from '@mui/material';

const RoomIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -2 24 24" {...props}>
      <path d="M15 10a3 3 0 1 0-.002-6.002A3 3 0 0 0 15 10zm0-4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM12 15c0-.975 2.694-1 3-1 .306 0 3 .025 3 1v1h2v-1c0-2.206-2.238-3-5-3s-5 .794-5 3v1h2v-1z" />
      <path d="M22 19c1.106 0 2-.894 2-2V2c0-1.106-.894-2-2-2H2C.894 0 0 .894 0 2v15c0 1.106.894 2 2 2h20zm0-2H2V2h20v15z" />
      <path d="M5 13h3c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1zM5 9h3c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1z" />
    </SvgIcon>
  );
};

export default RoomIcon;
