import { useTranslation } from 'react-i18next';
import {
  Box, Typography, Button, Link
} from '@mui/material';

const NotFound = ({ isLoggedIn }) => {
  const { t } = useTranslation();

  return (
    <Box color="white">
      <Typography color="white" textTransform="uppercase" variant="h1" mb={4}>{t('applicationError')}</Typography>
      <Typography variant="h6" color="white" my={5}>{t('applicationErrorDescription')}</Typography>
      <Button
        color="light"
        component={Link}
        size="large"
        fullWidth
        href="/"
        sx={{ fontWeight: 600, maxWidth: 450, px: 8 }}
      >
        {t(isLoggedIn ? 'backToStatsPage' : 'backToHomePage')}
      </Button>
    </Box>
  );
};

export default NotFound;
