import { SvgIcon } from '@mui/material';

const DownloadIcon = (props) => {
  return (
    <SvgIcon viewBox="8 8 24 24" {...props}>
      <path d="M28 27H12v2h16v-2zM19 14.831V25h2V14.831l4.587 4.582L27 18l-7-7-7 7 1.412 1.413L19 14.83z" />
    </SvgIcon>
  );
};

export default DownloadIcon;
