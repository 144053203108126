import { SvgIcon } from '@mui/material';

const SessionIcon = (props) => {
  return (
    <SvgIcon viewBox="0 -1.5 24 24" {...props}>
      <path d="M13.981 19.775c-.006-.056-.006-.112-.019-.169a3.232 3.232 0 0 0-.068-.337 3.275 3.275 0 0 0-.225-.644 3.088 3.088 0 0 0-.357-.581 4.035 4.035 0 0 0-1.018-.907 5.71 5.71 0 0 0-1.238-.575 8.754 8.754 0 0 0-1.325-.337c-.9-.163-1.812-.219-2.725-.225-.912 0-1.825.063-2.725.225-.45.081-.894.194-1.325.337-.431.15-.85.332-1.237.575a3.81 3.81 0 0 0-1.02.907 3.085 3.085 0 0 0-.355.581 3.272 3.272 0 0 0-.225.644c-.032.112-.044.225-.07.337-.012.056-.012.113-.018.169a1.532 1.532 0 0 0-.012.169c-.013.225-.007.825-.007 1.056h2v-.944c0-.031.007-.056.007-.087.006-.025 0-.056.006-.082.012-.05.012-.106.031-.15.044-.193.119-.33.225-.48.063-.07.125-.145.206-.22.088-.068.175-.143.294-.212.106-.069.238-.137.375-.2.144-.063.288-.125.45-.175a7.871 7.871 0 0 1 1.038-.262A13.676 13.676 0 0 1 7.012 18c.82 0 1.625.056 2.37.188.368.068.718.156 1.037.262.162.05.306.113.45.175.137.063.268.131.375.2.112.069.206.144.293.213.082.068.144.143.207.218.106.144.18.288.225.482.012.043.018.1.03.15.007.024.007.056.007.08 0 .032.006.057.006.088V21h2c-.012-.231-.012-.825-.018-1.056 0-.056-.013-.113-.013-.169zM22 0H6C4.894 0 4 .894 4 2v1c0 .55.45 1 1 1s1-.45 1-1V2h16v11h-8c-.55 0-1 .45-1 1s.45 1 1 1h8c1.106 0 2-.894 2-2V2c0-1.106-.894-2-2-2zM11 10c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4zm-6 0c0-1.106.894-2 2-2 1.106 0 2 .894 2 2 0 1.106-.894 2-2 2-1.106 0-2-.894-2-2zm15.006-5c0-.55-.45-1-1-1H13c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1h6.006c.55 0 1-.45 1-1V5zm-1 4h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1z" />
    </SvgIcon>
  );
};

export default SessionIcon;
