import { SvgIcon } from '@mui/material';

const CloseIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path d="m8.5 7 5.5 5.5-1.5 1.5L7 8.5 1.5 14 0 12.5 5.5 7 0 1.5 1.5 0 7 5.5 12.5 0 14 1.5 8.5 7z" />
    </SvgIcon>
  );
};

export default CloseIcon;
