import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useHubspot } from 'hooks/trackers';
import { useAuth } from 'hooks';
import { isDevelopment } from 'helpers';

const Trackers = ({ children }) => {
  useHubspot();
  const { auth }  = useAuth();
  const posthog   = usePostHog();

  if (isDevelopment()) {
    posthog.debug();
  }

  useEffect(() => {
    const disableCapturing = auth && auth.last_team && auth.last_team.internal;

    if (disableCapturing && !posthog.has_opted_out_capturing()) {
      posthog.opt_out_capturing();
    }

    if (!disableCapturing) {
      if (posthog.has_opted_out_capturing()) {
        posthog.opt_in_capturing();
      }
      if (auth.id && auth.last_team) {
        posthog.identify(
          auth.id, { plan: auth.last_team.plan.name }
        );
      }
    }
  }, [auth, posthog]);

  return children;
};

export default Trackers;
