import { SvgIcon } from '@mui/material';

const FolderIcon = (props) => {
  return (
    <SvgIcon viewBox="-1 -4 24 24" {...props}>
      <path d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H8L10 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H2Z" />
    </SvgIcon>
  );
};

export default FolderIcon;
