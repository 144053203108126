import { Stack, Button, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth, useAdmin, useEnv } from 'hooks';

const buttonStyles = (theme) => ({
  paddingLeft: 5,
  paddingRight: 5,
  borderRadius: 50,
  fontWeight: 600,
  [theme.breakpoints.down('xxl')]: {
    fontSize: '0.85rem'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.8rem'
  }
});

const UpgradeButton = styled((props) => {
  const { t } = useTranslation();

  return (
    <Button to="/upgrade" fullWidth component={RouterLink} {...props}>
      {t('upgradePlan')}
    </Button>
  );
})(({ theme }) => buttonStyles(theme));

const OutlinedButton = styled(({ href, children, ...rest }) => {
  return (
    <Button variant="outlined" component={Link} href={href} target="_blank" fullWidth {...rest}>
      {children}
    </Button>
  );
})(({ theme }) => ({
  ...buttonStyles(theme),
  lineHeight: 1.675,
  '&, &:hover': {
    border: `1px solid ${theme.palette.primary.main}`
  }
}));

const Buttons = () => {
  const {
    auth: {
      id:         accountId,
      last_team:  team
    }
  } = useAuth();

  const { t } = useTranslation();
  const helpUrl = useEnv('HELP_URL');
  const demoUrl = useEnv('DEMO_URL');

  const { isAdminPage } = useAdmin();

  if (isAdminPage) {
    return null;
  }

  return (
    <Stack
      textAlign="center"
      gap={2}
      mt={{ xs: 3, xl: 4, xxl: 5 }}
      mb={{ xs: 2, xxl: 3 }}
      px={{ xs: 1, md: 2, xl: 3 }}
    >
      {(team.owner_id === accountId) && <UpgradeButton />}
      <OutlinedButton href={helpUrl}>{t('needHelp')}</OutlinedButton>
      <OutlinedButton href={demoUrl}>{t('onlineDemo')}</OutlinedButton>
    </Stack>
  );
};

export default Buttons;
