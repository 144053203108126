import { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { isConfidentialDataAllowed } from 'helpers';
import AuthContext from 'context/AuthContext';

const useAuth = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const resetAuth = () => {
    setAuth({});
    queryClient.clear();
  };

  const setTeam = (data) => {
    const teams = auth.teams.map((team) => {
      if (data.id === team.id) {
        return Object.keys(team).reduce((accumulator, key) => {
          accumulator[key] = (key in data) ? data[key] : team[key];
          return accumulator;
        }, {});
      }
      return team;
    });

    setAuth({
      ...auth,
      teams,
      last_team: data
    });

    queryClient.removeQueries({
      queryKey: ['team', data.id]
    });
  };

  return {
    auth,
    setAuth,
    setTeam,
    resetAuth,
    isConfidentialAllowed: auth.last_team && isConfidentialDataAllowed(auth, auth.last_team)
  };
};

export default useAuth;
