import { Box } from '@mui/material';
import Copyrights from 'components/Layouts/Components/Copyrights';

const Footer = ({ dark }) => {
  return (
    <Box p={3}>
      <Copyrights color={dark && 'blue.contrastText'} />
    </Box>
  );
};

export default Footer;
