import { useState, useEffect, useMemo } from 'react';
import { useIsFetching, useIsMutating, useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';
import { getState } from 'queries/account';
import { useFavicon, useTitle, useEnv } from 'hooks';
import CircularLoading from 'components/Common/CircularLoading';
import AuthContext from 'context/AuthContext';

const whiteLabelPages = [
  '/systemcheck',
  '/network-info'
];

const isWhiteLabel = whiteLabelPages.includes(window.location.pathname);

const Loading = ({ show }) => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const [isLoading, setIsLoading] = useState(show);

  useEffect(() => {
    setIsLoading(show || isFetching || isMutating);
  }, [show, isFetching, isMutating]);

  return <CircularLoading show={isLoading} />;
};

const AppContainer = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const memoizedAuth = useMemo(() => ({ auth, setAuth }), [auth]);
  const appName = useEnv('NAME');

  useFavicon(!isWhiteLabel && '/favicon-32x32.png');
  useTitle(!isWhiteLabel ? appName : '');

  useEffect(() => {
    if (isWhiteLabel) {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useQuery({
    queryKey: ['state'],
    queryFn: getState,
    refetchOnReconnect: 'always',
    refetchInterval: auth ? 30 * 60 * 1000 : false,
    refetchIntervalInBackground: true,
    enabled: !isWhiteLabel,
    onSettled: (data) => {
      setAuth(data || {});
      setIsLoading(false);
    }
  });

  return (
    <Box className="AppContainer">
      <AuthContext.Provider value={memoizedAuth}>
        <Loading show={isLoading} />
        {!isLoading && children}
      </AuthContext.Provider>
    </Box>
  );
};

export default AppContainer;
