import { SvgIcon } from '@mui/material';

const LibraryIcon = (props) => {
  return (
    <SvgIcon viewBox="4 4 24 24" {...props}>
      <path d="M10 20h12l-3.75-5-3 4L13 16l-3 4z" />
      <path d="M26.413 9.588A1.925 1.925 0 0 0 25 9h-9l-2-2H7c-.55 0-1.02.196-1.412.588A1.925 1.925 0 0 0 5 9v14c0 .55.196 1.02.588 1.413.391.391.862.587 1.412.587h18c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413V11c0-.55-.196-1.02-.587-1.412zM25 23H7V9h6.175l2 2H25v12z" />
    </SvgIcon>
  );
};

export default LibraryIcon;
