import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import {
  QueryClient, QueryClientProvider, QueryCache, MutationCache
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CookiesProvider } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import AppContainer from 'components/AppContainer';
import ThemeProvider from 'components/ThemeProvider';
import Routes from 'routes/Routes';
import ErrorPage from 'pages/Error/Error';
import 'i18n/config';

const queryClient =  new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      keepPreviousData: false,
      retry: false
    }
  }
});

const resetState = (error, query) => {
  if (error.error === 'authentication' && !query.queryKey?.includes('state')) {
    queryClient.invalidateQueries({ queryKey: ['state'], refetchType: 'all' });
  }
};

queryClient.mutationCache = new MutationCache({
  onError: resetState
});

queryClient.queryCache = new QueryCache({
  onError: resetState
});

const App = () => (
  <CookiesProvider>
    <ThemeProvider>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        variant="success"
        preventDuplicate
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <AppContainer>
              <Routes />
            </AppContainer>
          </ErrorBoundary>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </CookiesProvider>
);

export default App;
